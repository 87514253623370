import type React from 'react';
import styles from './Banner.module.scss';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import Button from '../../../../stories/Atoms/Button/Button';
import Heading from '../../../../stories/Atoms/Heading/Heading';
import type { Banner as BannerComponentData } from '../ComponentTypes';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';

interface Props {
    component: BannerComponentData;
}

const Banner: React.FC<Props> = ({ component }) => {
    const withStaticUrl = useWithStaticUrl();

    return (
        <div className="section pt-0">
            <a href={component.url} className={styles.Banner}>
                <div className={styles.Banner__image}>
                    {component.imageUri && <Picture alt={component.alt || component.title} imageUrl={withStaticUrl(component.imageUri)}>
                        <Source background={false} ratio={false} imageWidth={2000} imageHeight={620}
                            minScreenWidth={2000}/>
                        <Source background={false} ratio={false} imageWidth={1440} imageHeight={620}
                            minScreenWidth={1440}/>
                        <Source background={false} ratio={false} imageWidth={1200} imageHeight={620}
                            minScreenWidth={1200}/>
                        <Source background={false} ratio={false} imageWidth={768} imageHeight={620}
                            minScreenWidth={768}/>
                        <Source background={false} ratio={false} imageWidth={768} imageHeight={560}/>
                    </Picture>}
                </div>
                <div className={styles.Banner__container}>
                    <div className={styles.Banner__content}>
                        <Heading tag="h1" asText
                            className={styles.Banner__content_title}>{component.title}</Heading>
                        <Heading tag="h5" asText
                            className={styles.Banner__content_subtitle}>{component.subtitle}</Heading>
                        {component.buttonText && <Button primary size="large">{component.buttonText}</Button> }
                    </div>
                </div>
            </a>
        </div>
    );
};

export default Banner;
