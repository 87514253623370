import { z } from 'zod';
import EmptyPhpRecord from '../../../ssr/src/Schema/EmptyPhpRecord';
import StringOrNullToUndefined from '../../../ssr/src/Schema/StringOrNullToUndefined';

export const HEADER_TYPE = 'header';
export const BANNER_TYPE = 'banner';
export const PRODUCTS_TYPE = 'products';
export const SEO_TEXT_TYPE = 'seo-text';
export const TEXT_TYPE = 'text';
export const CATEGORIES_TYPE = 'categories';
export const DESCRIPTION_TYPE = 'description';
export const PHOTOSLURP_TYPE = 'photoslurp';
export const PRODUCT_SLIDER_TYPE = 'product-slider';
export const USPS_TYPE = 'usps';
export const PROMOTION_TYPE = 'promotion';
export const BOXES_TYPE = 'boxes';
export const IMAGE_LAYOUT_TYPE = 'imageLayout';
export const BREADCRUMBS_TYPE = 'breadcrumbs';
export const HEADING_TYPE = 'heading';
export const NEWSLETTER_TYPE = 'newsletter';

const BannerSchema = z.object({
    alt: StringOrNullToUndefined,
    buttonText: z.string(),
    id: z.string(),
    imageUri: z.string(),
    pageId: z.number(),
    pageIdPlaceholder: StringOrNullToUndefined,
    subtitle: z.string(),
    title: z.string(),
    type: z.literal(BANNER_TYPE),
    url: StringOrNullToUndefined,
});
export type Banner = z.infer<typeof BannerSchema>;

const HeaderSchema = z.object({
    alt: z.string(),
    breadcrumbs: z.record(z.string()).optional(),
    id: z.string(),
    image: z.string(),
    isSmartImage: z.boolean(),
    squareImage: z.string(),
    title: z.string(),
    type: z.literal(HEADER_TYPE),
});
export type Header = z.infer<typeof HeaderSchema>;

const EavSetupSchema = z.object({
    attributeId: z.number().optional(),
    attributeName: z.string().optional(),
    type: z.literal('eav'),
    valueId: z.number().optional(),
    valueName: z.string().optional(),
});
export type EavSetup = z.infer<typeof EavSetupSchema>;

const ParentSchema = z.object({ pageId: z.number(), type: z.literal('parent') });
const NoneSchema = z.object({ type: z.literal('none') });
const DiscountSchema = z.object({ type: z.literal('discount') });
const BestsellersSchema = z.object({ type: z.literal('bestsellers') });
const NewProductsSchema = z.object({ type: z.literal('new-products') });
const FeaturedSchema = z.object({ type: z.literal('featured') });
const CollectionSetupSchema = z.object({
    setups: z.record(
        // We'll have to disable this rule because there's no other way to circularly define this schema.
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        z.lazy(() => PageSetupSchema),
    ),
    type: z.literal('collection'),
});
export type CollectionSetup = z.infer<typeof CollectionSetupSchema>;

export type PageSetup =
    | z.infer<typeof ParentSchema>
    | z.infer<typeof NoneSchema>
    | z.infer<typeof EavSetupSchema>
    | z.infer<typeof DiscountSchema>
    | z.infer<typeof BestsellersSchema>
    | z.infer<typeof NewProductsSchema>
    | z.infer<typeof FeaturedSchema>
    | { setups: Record<string, PageSetup>; type: 'collection' };

const PageSetupSchema: z.ZodType<PageSetup> = z.union([
    ParentSchema,
    NoneSchema,
    EavSetupSchema,
    DiscountSchema,
    CollectionSetupSchema,
    BestsellersSchema,
    NewProductsSchema,
    FeaturedSchema,
]);

export const GRADIENT_GREEN = 'green';
export const GRADIENT_RED = 'red';
export const GRADIENT_GREY = 'grey';
export const GRADIENT_YELLOW = 'yellow';
export const GRADIENT_BLUE = 'blue';

const GradientSchema = z.union([
    z.literal(GRADIENT_GREEN),
    z.literal(GRADIENT_RED),
    z.literal(GRADIENT_GREY),
    z.literal(GRADIENT_YELLOW),
    z.literal(GRADIENT_BLUE),
]);
export type Gradient = z.infer<typeof GradientSchema>;

export const LAYOUT_SETUP_BASIC = 'basic';
export const LAYOUT_SETUP_ADVANCED = 'advanced';

const LayoutSetupSchema = z.union([
    z.literal(LAYOUT_SETUP_BASIC),
    z.literal(LAYOUT_SETUP_ADVANCED),
]);
export type LayoutSetup = z.infer<typeof LayoutSetupSchema>;

export const BOX_TYPE_SMALL = 'small';
export const BOX_TYPE_LARGE = 'large';

const BoxTypeSchema = z.union([
    z.literal(BOX_TYPE_SMALL),
    z.literal(BOX_TYPE_LARGE),
]);
export type BoxType = z.infer<typeof BoxTypeSchema>;

export const ONE_IMAGE = 1;
export const ONE_IMAGE_LARGE = 2;
export const THREE_IMAGES_SMALL = 3;
export const THREE_IMAGES_LARGE = 4;

const ImageSetupSchema = z.union([
    z.literal(ONE_IMAGE),
    z.literal(ONE_IMAGE_LARGE),
    z.literal(THREE_IMAGES_SMALL),
    z.literal(THREE_IMAGES_LARGE),
]);
export type ImageSetup = z.infer<typeof ImageSetupSchema>;

export const ALIGNMENT_CENTER = 'center';
export const ALIGNMENT_RIGHT = 'right';
export const ALIGNMENT_LEFT = 'left';
export const ALIGNMENT_BOTTOM = 'bottom';
export const ALIGNMENT_TOP = 'top';

export type Alignment = z.infer<typeof AlignmentSchema>;
const AlignmentSchema = z.union([
    z.literal(ALIGNMENT_CENTER),
    z.literal(ALIGNMENT_RIGHT),
    z.literal(ALIGNMENT_LEFT),
]);

export type AlignmentBannerMobile = z.infer<typeof AlignmentBannerMobileSchema>;
const AlignmentBannerMobileSchema = z.union([
    z.literal(ALIGNMENT_BOTTOM),
    z.literal(ALIGNMENT_TOP),
]);

export const ALIGNMENT_ROW = 'row';
export const ALIGNMENT_COLUMN = 'column';

export type AlignmentBlocksMobile = z.infer<typeof AlignmentBlocksMobileSchema>;
const AlignmentBlocksMobileSchema = z.union([
    z.literal(ALIGNMENT_ROW),
    z.literal(ALIGNMENT_COLUMN),
]);

export const SECTION_TYPE_XL = 'xl';
export const SECTION_TYPE_SM = 'sm';
const SectionTypeSchema = z.union([
    z.literal(SECTION_TYPE_XL),
    z.literal(SECTION_TYPE_SM),
]);

export const TEXT_POSITION_TOP = 'top';
export const TEXT_POSITION_CENTER = 'center';
export const TEXT_POSITION_BOTTOM = 'bottom';
export const TEXT_POSITION_NONE = 'none';
export type TextPosition = z.infer<typeof TextPositionSchema>;
const TextPositionSchema = z.union([
    z.literal(TEXT_POSITION_TOP),
    z.literal(TEXT_POSITION_CENTER),
    z.literal(TEXT_POSITION_BOTTOM),
    z.literal(TEXT_POSITION_NONE),
]);

const ProductsSchema = z.object({
    distinct: z.boolean(),
    hasFilter: z.boolean(),
    id: z.string(),
    imageOverrides: z.union([z.record(z.string()), EmptyPhpRecord]),
    limit: z.number().optional(),
    setup: PageSetupSchema,
    type: z.literal(PRODUCTS_TYPE),
});
export type Products = z.infer<typeof ProductsSchema>;

const SeoTextSchema = z.object({
    id: z.string(),
    text: z.string(),
    type: z.literal(SEO_TEXT_TYPE),
});
export type SeoText = z.infer<typeof SeoTextSchema>;

const TextSchema = z.object({
    id: z.string(),
    text: z.string(),
    type: z.literal(TEXT_TYPE),
});
export type Text = z.infer<typeof TextSchema>;

const CategoryLinkSchema = z.array(z.object({
    id: z.string(),
    imageUri: z.string(),
    isAdditional: z.boolean(),
    name: z.string(),
    pageId: z.number(),
    show: z.boolean(),
    url: z.string(),
}));
export type CategoryLinks = z.infer<typeof CategoryLinkSchema>;

const CategoriesSchema = z.object({
    id: z.string(),
    links: CategoryLinkSchema,
    type: z.literal(CATEGORIES_TYPE),
    withImages: z.boolean(),
});
export type Categories = z.infer<typeof CategoriesSchema>;

const DescriptionSchema = z.object({
    id: z.string(),
    text: z.string(),
    type: z.literal(DESCRIPTION_TYPE),
});
export type Description = z.infer<typeof DescriptionSchema>;

const PhotoslurpSchema = z.object({
    id: z.string(),
    photoslurpId: z.string(),
    type: z.literal(PHOTOSLURP_TYPE),
});
export type Photoslurp = z.infer<typeof PhotoslurpSchema>;

const ProductSliderSchema = z.object({
    buttonPageId: z.coerce.string().optional(),
    buttonPageIdPlaceholder: StringOrNullToUndefined,
    buttonText: z.string(),
    buttonUrl: StringOrNullToUndefined,
    gradient: GradientSchema.optional(),
    id: z.string(),
    layoutSetup: LayoutSetupSchema,
    setup: PageSetupSchema,
    title: z.string(),
    type: z.literal(PRODUCT_SLIDER_TYPE),
});
export type ProductSlider = z.infer<typeof ProductSliderSchema>;

const UspSchema = z.object({
    id: z.string(),
    imageUri: z.string(),
    pageId: z.number(),
    pageIdPlaceholder: StringOrNullToUndefined,
    text: z.string(),
    title: z.string(),
    url: StringOrNullToUndefined,
});
export type Usp = z.infer<typeof UspSchema>;

const UspsSchema = z.object({
    id: z.string(),
    items: z.array(UspSchema),
    layoutSetup: LayoutSetupSchema,
    title: z.string(),
    type: z.literal(USPS_TYPE),
});
export type Usps = z.infer<typeof UspsSchema>;

const PromotionItemSchema = z.object({
    alt: StringOrNullToUndefined,
    gradient: GradientSchema,
    id: z.string(),
    imageUri: z.string(),
    pageId: z.number(),
    pageIdPlaceholder: StringOrNullToUndefined,
    text: z.string(),
    title: z.string(),
    url: StringOrNullToUndefined,
});
export type PromotionItem = z.infer<typeof PromotionItemSchema>;

const PromotionSchema = z.object({
    alignment: AlignmentSchema,
    alignmentBannerMobile: AlignmentBannerMobileSchema,
    alignmentBlocksMobile: AlignmentBlocksMobileSchema,
    bannerAlt: StringOrNullToUndefined,
    bannerButtonText: z.string(),
    bannerImageUri: z.string(),
    bannerPageId: z.number(),
    bannerPageIdPlaceholder: StringOrNullToUndefined,
    bannerTextPosition: TextPositionSchema,
    bannerTitle: z.string(),
    bannerUrl: StringOrNullToUndefined,
    id: z.string(),
    lazyLoad: z.boolean(),
    promotionItems: z.array(PromotionItemSchema),
    sectionType: SectionTypeSchema,
    type: z.literal(PROMOTION_TYPE),
});
export type Promotion = z.infer<typeof PromotionSchema>;

const BoxSchema = z.object({
    id: z.string(),
    imageUri: z.string(),
    pageId: z.number(),
    pageIdPlaceholder: StringOrNullToUndefined,
    title: StringOrNullToUndefined,
    url: StringOrNullToUndefined,
    urlParams: StringOrNullToUndefined,
});
export type Box = z.infer<typeof BoxSchema>;

const BoxesSchema = z.object({
    asSlider: z.boolean(),
    boxItems: z.array(BoxSchema),
    boxType: BoxTypeSchema,
    editableTitles: z.boolean(),
    id: z.string(),
    title: z.string(),
    type: z.literal(BOXES_TYPE),
});
export type Boxes = z.infer<typeof BoxesSchema>;

const ImageSchema = z.object({
    id: z.string(),
    imageUri: z.string(),
    pageId: z.number(),
    pageIdPlaceholder: StringOrNullToUndefined,
    url: StringOrNullToUndefined,
    urlParams: StringOrNullToUndefined,
});
export type Image = z.infer<typeof ImageSchema>;

const ImageLayoutSchema = z.object({
    id: z.string(),
    images: z.array(ImageSchema),
    setupId: ImageSetupSchema,
    type: z.literal(IMAGE_LAYOUT_TYPE),
});
export type ImageLayout = z.infer<typeof ImageLayoutSchema>;

const BreadcrumbsSchema = z.object({
    breadcrumbs: z.record(z.string()),
    id: z.string(),
    type: z.literal(BREADCRUMBS_TYPE),
});
export type Breadcrumbs = z.infer<typeof BreadcrumbsSchema>;

const HeadingSchema = z.object({
    alignment: AlignmentSchema,
    hideOnMobile: z.boolean(),
    id: z.string(),
    isH1: z.boolean(),
    text: z.string(),
    title: z.string(),
    type: z.literal(HEADING_TYPE),
});
export type Heading = z.infer<typeof HeadingSchema>;

const NewsletterUspSchema = z.object({
    id: z.string(),
    text: z.string(),
});
export type NewsletterUsp = z.infer<typeof NewsletterUspSchema>;

const NewsletterSchema = z.object({
    buttonText: z.string(),
    description: z.string(),
    id: z.string(),
    successButtonText: z.string(),
    successText: z.string(),
    successTitle: z.string(),
    title: z.string(),
    type: z.literal(NEWSLETTER_TYPE),
    usps: z.array(NewsletterUspSchema),
});
export type Newsletter = z.infer<typeof NewsletterSchema>;

export type Component = z.infer<typeof ComponentSchema>;
export const ComponentSchema = z.discriminatedUnion('type', [
    HeaderSchema,
    ProductsSchema,
    SeoTextSchema,
    CategoriesSchema,
    DescriptionSchema,
    PhotoslurpSchema,
    ProductSliderSchema,
    UspsSchema,
    PromotionSchema,
    BoxesSchema,
    ImageLayoutSchema,
    BreadcrumbsSchema,
    HeadingSchema,
    TextSchema,
    NewsletterSchema,
    BannerSchema,
]);

export type Components = Component[];

export type PartialComponent<T extends Component = Component> = Partial<T> & Pick<T, 'id'>;
