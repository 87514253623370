import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import type { Box as BoxComponentType, BoxType } from '../ComponentTypes';
import Box from './Box';
import { BOX_TYPE_LARGE, BOX_TYPE_SMALL } from '../ComponentTypes';
import BoxSlider from './BoxSlider';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import styles from './Boxes.module.scss';

interface BoxesProps {
    addButton?: ReactElement;
    asSlider: boolean;
    boxType: BoxType;
    draggableItem?: ReactElement;
    draggableWrapper?: ReactElement;
    editButtons?: ReactElement[];
    editable: boolean;
    isLocked: boolean;
    items: BoxComponentType[];
    onTitleChange?: (value: string, index: number) => void;
    title: string;
    titleWrapper?: ReactElement;
}

const Boxes = React.forwardRef<HTMLDivElement, BoxesProps>(({
    addButton, asSlider, boxType, draggableItem, draggableWrapper, editButtons, editable, isLocked, items, onTitleChange, title, titleWrapper,
}, ref) => {
    const isBoxSmall = boxType === BOX_TYPE_SMALL;
    const isBoxLarge = boxType === BOX_TYPE_LARGE;
    const withStaticUrl = useWithStaticUrl();

    let boxItems = items;
    if (!editable) {
        boxItems = items.filter((i) => i.pageId > 0 && !!i.imageUri && !!i.url);
    }

    if (!editable && !boxItems.length) {
        return null;
    }

    const boxes = boxItems.map((item, index) => {
        if (!isLocked && draggableItem) {
            return React.cloneElement(
                draggableItem,
                { id: item.id, key: item.id },
                <Box editable={editable} editButtons={<>{}</>} index={index} item={item} key={item.id} type={boxType} />,
            );
        }
        return <Box
            editable={editable}
            editButtons={isLocked && editable ? editButtons?.[index] : <>{}</>}
            index={index}
            item={item}
            key={item.id}
            onTitleChange={onTitleChange}
            titleWrapper={titleWrapper}
            type={boxType}
        >
            {asSlider && <Picture alt={item.title ?? ''} imageUrl={withStaticUrl(item.imageUri)}>
                <Source background="ffffff" imageWidth={345}/>
            </Picture> }
        </Box>;
    });

    if ((!editable || (editable && isLocked)) && asSlider) {
        return <BoxSlider boxes={boxes} isLocked={isLocked} editable={editable} addButton={addButton} title={title} />;
    }

    return (
        <div className={classNames(
            'section section--md',
            { 'u-scrollable-x--mobile': isBoxSmall },
        )}>
            <div ref={ref} className={classNames(
                { 'wrapper wrapper--centered': isBoxSmall },
                { wrapper: isBoxLarge },
            )}>
                <div className={classNames(
                    { [styles.Boxes__small as string]: isBoxSmall },
                    { 'is-grid has-col-4-xl has-col-4-md has-col-2 has-row-gap-md': isBoxLarge },
                )}>
                    {React.cloneElement(
                        !isLocked && draggableWrapper ? draggableWrapper : <>{}</>,
                        {},
                        boxes,
                    )}
                    {isLocked && editable && addButton}
                </div>
            </div>
        </div>
    );
});

export default Boxes;
